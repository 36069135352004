<template>
<div>
    <div class="deactivateddialog-container">
        <img src="../../assets/images/trial.png" />
        <h4>{{ $t('explore.deactivated.title') }}</h4>
        <p style="text-align:center">{{ $t('explore.deactivated.caption') }}</p>

        <p v-if="enabledFeatures['premiumSection'] === false" style="padding: 10px; border: 1px solid rgba(var(--vs-primary), 0.5); border-radius: 5px; background: rgba(var(--vs-primary), 0.01); color: rgba(var(--vs-primary)); font-size: 0.8rem;  margin-top: 10px;">
            {{ $t('profile.subscription.notAvailable') }}
        </p>
        <vs-button size="large" block @click="callback(true, true)" v-if="enabledFeatures['premiumSection'] !== false">
            {{ $t('explore.deactivated.button') }}
        </vs-button>
    </div>

</div>
</template>

    
<script>
export default {
    name: 'deactivatedDialog',
    props: {
        callback: Function
    },

    computed: {
        enabledFeatures() {
            return this.$FEATURES.settings;
        }
    }
}
</script>

    
<style scoped>
p,
h4 {
    margin: 0;
}

img {
    margin-bottom: 20px;
}

.deactivateddialog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 40px;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
}
</style>

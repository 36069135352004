<template>
<div>
    <div class="offlinedialog-container">
        <img src="../../assets/images/internet_connection.png" style="width: 60%" />
        <h4>{{ $t('explore.offline.title') }}</h4>
        <p style="text-align: center;">{{ $t('explore.offline.caption') }}</p>
        <vs-button size="large" block @click="callback(true, true)">
            {{ $t('explore.offline.button') }}
        </vs-button>
    </div>

</div>
</template>

    
<script>
export default {
    name: 'offlineDialog',
    props: {
        callback: Function
    }
}
</script>

    
<style scoped>
p,
h4 {
    margin: 0;
}

img {
    margin-bottom: 20px;
}

.offlinedialog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 40px;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
}
</style>
